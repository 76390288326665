import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';

import styles from './Reflections.module.css';

export default function Reflections({
  reflection,
  onChange,
  prompt,
  id,
}: {
  reflection: string;
  id: string;
  onChange: OutlinedInputProps['onChange'];
  prompt: React.ReactNode;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.prompt}>{prompt}</div>
      <TextField
        id={id}
        multiline
        rows={10}
        fullWidth
        placeholder={t('journal.entry.content')}
        margin="normal"
        variant="outlined"
        value={reflection}
        InputProps={{
          classes: {
            root: styles.field,
          },
        }}
        onChange={onChange}
      />
    </div>
  );
}
